import React from 'react';
function Blog() {
  return (
    <>
    <section
      className="inner-banner d-flex align-items-center justify-content-center text-center"
      style={{ backgroundImage: 'url("images/blog-banner.jpg")' }}
    >
      <div className="inner-banner-content">
        <div className="container">
          <h1 className="text-white fw-bolder">Blogs</h1>
        </div>
      </div>
    </section>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav>
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Blog
            </li>
          </ol>
        </nav>
      </div>
    </section>
    <section className="blog-col-6 right-black-bg">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="300ms"
          >
            <div className="blog-6-list position-relative overflow-hidden">
              <div className="blog-6-list-img">
                <img src="images/blog1.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-6-content d-flex position-absolute bottom-0 left-0 w-100">
                <div className="b-6-left">
                  <h4>Understanding the Highest Level of Body Armor</h4>
                  <span>June 12, 2022</span>
                </div>
                <div className="b-6-right">
                  <a href="blog-detail.php" className="blog-6-btn">
                    <i className="fa-solid fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="600ms"
          >
            <div className="blog-6-list position-relative overflow-hidden">
              <img src="images/blog2.jpg" className="img-fluid w-100" />
              <div className="blog-6-content d-flex position-absolute bottom-0 left-0 w-100">
                <div className="b-6-left">
                  <h4>Female Specific Body Armour</h4>
                  <span>June 12, 2022</span>
                </div>
                <div className="b-6-right">
                  <a href="blog-detail.php" className="blog-6-btn">
                    <i className="fa-solid fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="blog-3-blk mt-40 pb-100">
      <div className="container">
        <div className="row gy-4">
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="900ms"
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src="images/column-4.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                  Combat Goggles and Integrated Visual Augmentation System
                </h4>
                <a href="#" className="know-more ">
                  Read more <img src="images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          {/* col-lg-4 */}
        </div>
      </div>
    </section>


    
  </>
  )
}

export default Blog
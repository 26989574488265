import React from 'react'
import { useForm } from "react-hook-form";

function Contact() {
  const { register, handleSubmit, formState: { errors }} = useForm();
  const onSubmit = values => console.log(values);
  return (
    <>
    <section
      className="inner-banner d-flex align-items-center justify-content-center text-center"
      style={{ backgroundImage: 'url("images/contact.jpg")' }}
    >
      <div className="inner-banner-content">
        <div className="container">
          <h1 className="text-white fw-bolder">Contact Us</h1>
        </div>
      </div>
    </section>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav>
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item">
              <a href="https://hcltechbeenew.tangence.com">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact Us
            </li>
          </ol>
        </nav>
      </div>
    </section>
    <section className="contact-us pb-100">
      <div className="container">
        <h2>Indian Armour</h2>
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="contact-us-left">
              <p className="d-flex align-items-center">
                <span className="address-icon">
                  <img src="images/map.svg" />
                </span>{" "}
                <span className="address-content">
                  Universal Lane, Village Dudhola,
                  <br /> P O Bhagola Palwal, Haryana-121102, India
                </span>
              </p>
              <p className="d-flex align-items-center">
                <span className="address-icon">
                  <img src="images/phone.svg" />
                </span>{" "}
                <span className="address-content">
                  <a href="tel:+91-9811268600">+91-9811268600,</a>
                  <br />
                  <a href="tel:+91-8750050215">+91-8750050215</a>
                </span>
              </p>
              <p className="d-flex align-items-center">
                <span className="address-icon">
                  <img src="images/envlop.svg" />
                </span>{" "}
                <span className="address-content">
                  <a href="mailto:info@indianarmour.com">
                    info@indianarmour.com
                  </a>
                  <br />
                  <a href="mailto:prerna@indianarmour.com">
                    prerna@indianarmour.com
                  </a>
                </span>
              </p>
              <div className="d-flex ">
                <span className="address-icon ">
                  <img src="images/envlop.svg" className="invisible" />
                </span>
                <span className="address-content">
                  <div className="social-media">
                    <span>Follow Us:</span>
                    <ul className="d-flex list-inline m-0">
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="contact-us-right">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Full Name *"
                    {...register("fullName", { required: true })}
                  />
                  {errors.fullName && <span className='error'>Full Name is required</span>}
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address *"
                    {...register("email", { required: true })}
                  />
                   {errors.email && <span className='error'>Email Address is required</span>}
                </div>
                <div className=" mb-3 position-relative mobile-number">
                  <span className="p-number">+91</span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile Numeber *"
                    {...register("phone", { required: true, min: 10 })}
                  />
                  {errors.phone && <span className='error'>Phone Number required</span>}
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    defaultValue={""}
                    {...register("message", { required: true })}
                  />
                   {errors.message && <span className='error'>Message is required</span>}
                </div>
                <div className="input-group justify-content-center mt-5">
                  <button className="btn know-more-btn submit">
                    Submit
                    <span className="input-group-text">
                      <img src="images/arrow.svg" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className=" map-blk position-relative">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3515.7999046575164!2d77.27766547422252!3d28.21339130299315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cd6c5710b5431%3A0x1c95c657dfd5037e!2sIndian%20Armour%20Systems%20pvt%20ltd!5e0!3m2!1sen!2sin!4v1683610539547!5m2!1sen!2sin"
        width="100%"
        height={450}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </section>
  </>
  )
}

export default Contact
import React from 'react'
import {Link, NavLink} from "react-router-dom";

function Header() {
  return (
    <>
  <header>
    <nav className="navbar navbar-expand-md navbar-light ">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="images/logo.svg" alt="" className="img-fluid" />
        </Link>
        <button
          className="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <Link
          to="/"
          className="offcanvas-hemburg mobile"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i className="fa-solid fa-bars" />
        </Link>
        <div className="collapse navbar-collapse" id="mynavbar">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item active">
              <NavLink className="nav-link " to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="product">
                Products
              </NavLink>
            </li>
            {/*  <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">Blog</a>
      </li> */}
            <li className="nav-item">
              <NavLink className="nav-link" to="contact">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <Link
          to="/"
          className="offcanvas-hemburg desktop"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i className="fa-solid fa-bars" />
        </Link>
      </div>
    </nav>
  </header>
  <div
    className="offcanvas offcanvas-end"
    tabIndex={-1}
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div className="offcanvas-header">
      <button
        type="button"
        className="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>
    <div className="offcanvas-body">
      <div className="row">
        <div className="col-lg-6">
          <div className="offcanvas-block">
            <h4>Categories</h4>
            <ul className="offcanvas-list">
              <li>
              <Link to="ballisticprotection">Ballistic Protection</Link>
              </li>
              <li>
                
                <Link to="ballistichelmet">Ballistic Helmet</Link>
              </li>
              <li>
                <a href="#">Ballistic Plates</a>
              </li>
              <li>
              <Link to="ballisticvests">Ballistic Vest</Link>
                
              </li>
              <li>
                <a href="#">Gear</a>
              </li>
              <li>
                <a href="#">Head Gear</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="offcanvas-block">
            <h4>Resources</h4>
            <ul className="offcanvas-list">
              <li>
                <a href="#">Brochure</a>
              </li>
              <li>
              <Link to="about">About Us</Link>
              </li>
              <li>
                <Link to="contact">Contact Us</Link>
              </li>
              <li>
                <Link to="blog">Blog</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="offcanvas-social">
        <div className="social-media d-flex align-items-center top-social">
          <span>Follow Us:</span>
          <ul className="d-flex list-inline m-0">
            <li>
              <a href="#">
                <i className="fa-brands fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-linkedin-in" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-twitter" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-instagram" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa-brands fa-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</>
  )
}

export default Header
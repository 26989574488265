import React from 'react'
import { Helmet } from "react-helmet"

function BallisticVests() {
  return (
    <div>
      <Helmet>
        <script src="js/vests.js"></script>
      </Helmet>
    <section className="inner-banner2 " style={{backgroundImage: 'url("images/Banner2.png")'}}>
      <div className="inner-banner2-content d-flex align-items-center">
        <div className="container">
          <h1 className=" fw-bolder">Ballistic Vests</h1>
        </div>
      </div>
    </section>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav >
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item"><a href="index.php">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Ballistic Vests</li>
          </ol>
        </nav>
      </div>
    </section>
    {/* banner */}
    <section className="ballistic-vesta-banner" style={{backgroundImage: 'url("images/bannerg.png")'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p0">
            <div className="ballistic-vesta-left">
              <div className="ballistic-vesta-text">
                <h1>Ballistic Vests</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="ballistic-vesta-right" data-aos="fade-left">
              <div className="ballistic-vesta-box">
                <ul>
                  <li className="vesta-box"><img src="images/banner-icon-1.svg" /></li>
                  <li>Bespoke<br />Solutions</li>
                </ul>
              </div>
              <div className="ballistic-vesta-box">
                <ul>
                  <li className="vesta-box"><img src="images/banner-icon-2.svg" /></li>
                  <li>Bulk <br />Ordering</li>
                </ul>
              </div>
              <div className="ballistic-vesta-box">
                <ul>
                  <li className="vesta-box"><img src="images/banner-icon-3.svg" /></li>
                  <li>5 Year<br /> Warranty</li>
                </ul>
              </div>
              <div className="ballistic-vesta-box">
                <ul>
                  <li className="vesta-box"><img src="images/banner-icon-4.svg" /></li>
                  <li>NIJ<br /> Certified</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* banner */}
    <div className="verticale-slider">
      <div className="header-tabspy">
        <div className="row">
          <div id="list-example" className="list-group fised-navs">
            <a className="list-group-item list-group-item-action" href="#scroll1">Front Open Molle Webbing Vest</a>
            <a className="list-group-item list-group-item-action" href="#scroll2">Ballistic Flotation Vest</a>
            <a className="list-group-item list-group-item-action" href="#scroll3">General Purpose Vest</a>
            <a className="list-group-item list-group-item-action" href="#scroll4">Military Vest</a>
            <a className="list-group-item list-group-item-action" href="#scroll5">Front Open Molle Webbing Vest</a>
            <a className="list-group-item list-group-item-action" href="#scroll6">Ballistic Flotation Vest</a>
          </div>
        </div>
      </div>
      {/* Start Nav Section */}
      <nav id="navbar1" className="navbar1">
        <ul className="nav-menu1">
          <li>
            <a data-scroll="scroll1" href="#scroll1" className="dot1 active1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll2" href="#scroll1" className="dot1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll3" href="#scroll3" className="dot1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll4" href="#scroll4" className="dot1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll5" href="#scroll5" className="dot1">
            </a>
          </li>
          <li>
            <a data-scroll="scroll6" href="#scroll6" className="dot1">
            </a>
          </li>
        </ul>
      </nav>
      {/* End Nav Section */}
      {/* left right */}
      <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>
        <section className="vest-left-box section" id="scroll1">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
                  <img src="images/vest-1.png" />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                  <div className="vest-left-y-text">
                    <h2>Front open Molle Webbing Vest</h2>
                    <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. With a simple front jacket style solution, it provides excellent protection, while remaining simple to use. It comes complete with MOLLE attachments and load-out points, allowing full tactical use.</p>
                    <div className="input-group mt-5">
                      <a href="#" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="images/arrow.svg" /></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* left right */}
        {/* left right */}
        <section className="vest-left-box vest-left-box2 section" id="scroll2">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                  <div className="vest-left-y-text">
                    <h2>General Purpose Vest</h2>
                    <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. With a simple front jacket style solution, it provides excellent protection, while remaining simple to use. It comes complete with MOLLE attachments and load-out points, allowing full tactical use.</p>
                    <div className="input-group mt-5">
                      <a href="#" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="images/arrow.svg" /></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
                  <img src="images/vest-2.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* left right */}
        {/* left right */}
        <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>
          <section className="vest-left-box section" id="scroll3">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="images/vest-1.png" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                  <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                    <div className="vest-left-y-text">
                      <h2>Front open Molle Webbing Vest</h2>
                      <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. With a simple front jacket style solution, it provides excellent protection, while remaining simple to use. It comes complete with MOLLE attachments and load-out points, allowing full tactical use.</p>
                      <div className="input-group mt-5">
                        <a href="#" className="btn know-more-btn submit">View Product
                          <span className="input-group-text"><img src="images/arrow.svg" /></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* left right */}
          {/* left right */}
          <section className="vest-left-box vest-left-box2 section" id="scroll4">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                  <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                    <div className="vest-left-y-text">
                      <h2>General Purpose Vest</h2>
                      <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. With a simple front jacket style solution, it provides excellent protection, while remaining simple to use. It comes complete with MOLLE attachments and load-out points, allowing full tactical use.</p>
                      <div className="input-group mt-5">
                        <a href="#" className="btn know-more-btn submit">View Product
                          <span className="input-group-text"><img src="images/arrow.svg" /></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
                    <img src="images/vest-2.png" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* left right */}
          {/* left right */}
          <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>
            <section className="vest-left-box section" id="scroll5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                    <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
                      <img src="images/vest-1.png" />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                    <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                      <div className="vest-left-y-text">
                        <h2>Front open Molle Webbing Vest</h2>
                        <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. With a simple front jacket style solution, it provides excellent protection, while remaining simple to use. It comes complete with MOLLE attachments and load-out points, allowing full tactical use.</p>
                        <div className="input-group mt-5">
                          <a href="#" className="btn know-more-btn submit">View Product
                            <span className="input-group-text"><img src="images/arrow.svg" /></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* left right */}
            {/* left right */}
            <section className="vest-left-box vest-left-box2 section" id="scroll6">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                    <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                      <div className="vest-left-y-text">
                        <h2>General Purpose Vest</h2>
                        <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. With a simple front jacket style solution, it provides excellent protection, while remaining simple to use. It comes complete with MOLLE attachments and load-out points, allowing full tactical use.</p>
                        <div className="input-group mt-5">
                          <a href="#" className="btn know-more-btn submit">View Product
                            <span className="input-group-text"><img src="images/arrow.svg" /></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                    <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
                      <img src="images/vest-2.png" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* left right */}
          </div>
        </div>
        <section className="view-all-product">
          <div className="container">
            <div className="row">
              <div className="view-all-btn">
                <div className="input-group mt-5">
                  <a href="#" className="btn know-more-btn submit">View All Products
                    <span className="input-group-text"><img src="images/arrow.svg" /></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Premium quality Ballistic Vests by Indian Armour */}
        <section className="premium-quality">
          <div className="container">
            <div className="premium-he"><h2>Premium quality Ballistic Vests<br />by Indian Armour</h2></div>
            <div className="premium-quality-left">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                  <div className="premium-text" data-aos="fade-right" data-aos-offset={100}>
                    <div className="premium-text-box">
                      <p>Our ballistic vests are made of premium quality hardwearing fabric and are designed to meet the specific needs of the wearer. All our personal protection armour products are rigorously and continuously tested to ensure that they meet the standards set by the NIJ (National Institute of Justice). We are a global supplier of high-performance ballistic products and strive towards meeting the specific requirements of our customers. We are an international supplier catering to countries in the EU and Africa to even the Middle East and Americas.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                  <div className="premium-quality-img" data-aos="fade-left" data-aos-offset={100}>
                    <img src="images/quality-1.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="premium-quality-right">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                  <div className="premium-quality-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="images/quality-2.png" />
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                  <div className="premium-text" data-aos="fade-left" data-aos-offset={100}>
                    <div className="premium-text-box-right">
                      <p>The nature of defensive operations demands nothing short of perfection since the slightest error could turn out to be deadly. Being a world-class premium ballistic vest manufacturer, we ensure that our manufactured products are completely reliable. These vests are also available for multi-threat applications. We guarantee our bullet proof clothing products provide protection against a large range of ammunition, stabs, and spikes, even in stealth and tactical applications. By providing security personnel with a broad choice of customized protective gear, we offer the defense industry a complete solution of body armour products.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="premium-quality-left">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                  <div className="premium-text" data-aos="fade-right" data-aos-offset={100}>
                    <div className="premium-text-box">
                      <p>These products are perfect for use by the military, civil police forces, federal agencies, and first responders. Whether you need tactical gear, flexible body armour, outer vests, or plate carriers, we offer a wide range of options for you to choose from.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                  <div className="premium-quality-img" data-aos="fade-left" data-aos-offset={100}>
                    <img src="images/quality-3.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Premium quality Ballistic Vests by Indian Armour */}
        {/* 3d slider */}
        <section className="threed-sluder" style={{backgroundImage: 'url("images/BG_2.png")'}}>
          <div className="container">
            <div className="row">
              <div className="slider-section">
                <div className="rev_slider">
                  <div className="rev_slide">
                    <div className="test"><div className="threed-slider-contant"><img src="images/3d-slider-1.png" /><p>Quick-release Vest, and Many More</p></div></div>
                  </div>
                  <div className="rev_slide">
                    <div className="test"><div className="threed-slider-contant"><img src="images/3d-slider-1.png" /><p>Quick-release Vest, and Many More</p></div>
                    </div>
                  </div>
                  <div className="rev_slide">
                    <div className="test"><div className="threed-slider-contant"><img src="images/3d-slider-1.png" /><p>Quick-release Vest, and Many More</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 3d slider */}
        {/* What are the NIJ Levels for Ballistic Vests? */}
        <section className="nij-levels">
          <div className="container">
            <div className="nij-levels-he"><h2>What are the NIJ Levels for Ballistic Vests?</h2></div>
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="nij-levels-img" data-aos="fade-right" data-aos-offset={300}>
                  <img src="images/nij-1.png" />
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="nij-levels-text" data-aos="fade-left" data-aos-offset={300}>
                  <h3>Body armour is divided into five tiers by the NIJ standard 0101.06. Each class provides enhanced bullet proofing, stopping stronger and faster projectiles before they penetrate more than 44 mm.</h3>
                  <p>At Indian Armour, we offer vests within the top NIJ levels. All of our ballistic vest solutions are categorised according to their NIJ ratings and are tested in recognised laboratories such as NTS Chesapeake, in accordance with the worldwide standard NIJ (National Institute of Justice). Our products are tested at accredited laboratories to ensure that they meet or surpass its target NIJ level.</p>
                  <p> Across various testing standards for bullet proof vests, NIJ standard 0101.06 is one of the widely accepted standards for a bulletproof vest, which means that the vest has should be tested through strict testing methods in a professional laboratory according to the NIJ requirements. This will assure that the vests are bulletproof and can defend off the threats imposed by the NIJ.</p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* nij tab */}
              <div className="tab-he"> <h2>The various NIJ levels in use are as follows</h2></div>
              <div className="nij-tab" data-aos="fade-up" data-aos-duration={1000}>
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link  active" data-bs-toggle="pill" href="#Level1" aria-selected="false" role="tab" tabIndex={-1}>
                      Level IV
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#Level2" aria-selected="true" role="tab">
                      Level IIA
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#Level3" aria-selected="false" tabIndex={-1} role="tab">
                      Level II
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#Level4" aria-selected="false" tabIndex={-1} role="tab">
                      Level IIIA
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#Level5" aria-selected="false" tabIndex={-1} role="tab">
                      Level III
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane container fade active show" id="Level1" role="tabpanel">
                    <div className="nij-level">
                      <img src="images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>The NIJ Level IV body armours are designed to offer the highest basic level of protection. It is made up of hard plates, as opposed to Level IIIA and lower-level plates.</p>
                          <p> Level IV armour meets this criterion by stopping a single shot from 7.62mm armour piercing rifle (APR) bullet rather than the six that Level III armour does, therefore it isn’t necessarily superior to Level III armour. The Level IV body armour can resist more than one shot from essentially any pistol, assault rifle or sniper rifle.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade " id="Level2" role="tabpanel">
                    <div className="nij-level">
                      <img src="images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>The NIJ Level IV body armours are designed to offer the highest basic level of protection. It is made up of hard plates, as opposed to Level IIIA and lower-level plates.</p>
                          <p> Level IV armour meets this criterion by stopping a single shot from 7.62mm armour piercing rifle (APR) bullet rather than the six that Level III armour does, therefore it isn’t necessarily superior to Level III armour. The Level IV body armour can resist more than one shot from essentially any pistol, assault rifle or sniper rifle.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="Level3" role="tabpanel">
                    <div className="nij-level">
                      <img src="images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>The NIJ Level IV body armours are designed to offer the highest basic level of protection. It is made up of hard plates, as opposed to Level IIIA and lower-level plates.</p>
                          <p> Level IV armour meets this criterion by stopping a single shot from 7.62mm armour piercing rifle (APR) bullet rather than the six that Level III armour does, therefore it isn’t necessarily superior to Level III armour. The Level IV body armour can resist more than one shot from essentially any pistol, assault rifle or sniper rifle.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="Level4" role="tabpanel">
                    <div className="nij-level">
                      <img src="images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>The NIJ Level IV body armours are designed to offer the highest basic level of protection. It is made up of hard plates, as opposed to Level IIIA and lower-level plates.</p>
                          <p> Level IV armour meets this criterion by stopping a single shot from 7.62mm armour piercing rifle (APR) bullet rather than the six that Level III armour does, therefore it isn’t necessarily superior to Level III armour. The Level IV body armour can resist more than one shot from essentially any pistol, assault rifle or sniper rifle.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="Level5" role="tabpanel">
                    <div className="nij-level">
                      <img src="images/nij-level.png" />
                      <div className="nij-level-text">
                        <div className="nij-level-center">
                          <p>The NIJ Level IV body armours are designed to offer the highest basic level of protection. It is made up of hard plates, as opposed to Level IIIA and lower-level plates.</p>
                          <p> Level IV armour meets this criterion by stopping a single shot from 7.62mm armour piercing rifle (APR) bullet rather than the six that Level III armour does, therefore it isn’t necessarily superior to Level III armour. The Level IV body armour can resist more than one shot from essentially any pistol, assault rifle or sniper rifle.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* nij tab */}
            </div>
            {/* */}
            <div className="row p20">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="kevlar-text" data-aos="fade-right" data-aos-offset={300}>
                  <h2>What is Kevlar?</h2>
                  <p>Kevlar is a type of material used in body armour that protects you from gunshots. It’s a well-known material that’s used to manufacture ballistic vests, stab-proof vests, and plate carriers. Kevlar is a crucial material used in the US military’s PASGT helmet. Its high heat resistance uniforms, body armour used by police officers, security, and police tactical units such as SWAT are just a few of its civilian applications.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="kevlar" data-aos="fade-left" data-aos-offset={300}>
                  <img src="images/img-1.png" />
                </div>
              </div>
            </div>
            {/* */}
            {/* */}
            <div className="row wearing-body-sec">
              <div className="wearing-body"><h2>Is wearing body armour in public considered illegal?</h2></div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="kevlar-text" data-aos="fade-right" data-aos-offset={300}>
                  <p>In most circumstances, wearing body armour in public is lawful and civilians have the right to defend themselves and their loved ones. As a result, people in most of the Europe and the United States can buy bulletproof vests and other body armour. Wearing body armour while in possession of a hazardous weapon other than a handgun during the conduct or attempted commission of any crime is, nonetheless, forbidden. It’s also unlawful in several places to wear body armour if you’ve been convicted of a felony.</p>
                  <p> However, it is the buyer’s obligation to determine if he is legally permitted to acquire and use body armour. This data is true to the best of our knowledge. THIS IS NOT LEGAL ADVICE, AND IT SHOULD NOT BE TAKEN AS SUCH. Contact a licenced attorney in your jurisdiction for legal guidance. For the most up-to-date rules in your region, contact your local government and/or police enforcement.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="kevlar" data-aos="fade-left" data-aos-offset={300}>
                  <img src="images/img-2.png" />
                </div>
              </div>
            </div>
            {/* */}
            {/* Certificates */}
            <div className="Certificates">
              <div className="row g-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>
                <div className="col-lg-4 col-sm-4">
                  <ul className="nav nav-pills flex-column" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active" data-bs-toggle="pill" href="#home" aria-selected="false" role="tab" tabIndex={-1}>
                        <div className="sps-icon">
                          <img className="tapicon1" src="images/tabicon-1.png" /><img className="tapicon2" src="images/tabicon-1w.png" /> <span>Our Certificates</span>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link " data-bs-toggle="pill" href="#menu1" aria-selected="true" role="tab">
                        <div className="sps-icon">
                          <img className="tapicon1" src="images/tabicon-2.png" /><img className="tapicon2" src="images/tabicon-2w.png" /><span>Our Strengths</span>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" data-bs-toggle="pill" href="#menu2" aria-selected="false" tabIndex={-1} role="tab">
                        <div className="sps-icon">
                          <img className="tapicon1" src="images/tabicon-3.png" /><img className="tapicon2" src="images/tabicon-3w.png" /> <span>Our Machinery</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-8  col-sm-8 border">
                  <div className="tab-content">
                    <div className="tab-pane container fade active show" id="home" role="tabpanel">
                      <div className="Certificates-text">
                        <ul>
                          <li>Indian Armour products are NIJ certified and are listed on the NIJ website as a certified and approved supplier www.justnet.org</li>
                          <li> Our products are tested in both field-level and lab-level environments</li>
                          <li> Lab-testing occurs in various laboratories in India, the US, UK and EU regions</li>
                        </ul>
                        <div className="certified-img">
                          <img src="images/certificates-1.png" />
                          <img src="images/certificates-2.png" />
                          <img src="images/certificates-3.png" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane container fade" id="menu1" role="tabpanel">
                      <div className="Certificates-text">
                        <ul>
                          <li>Indian Armour products are NIJ certified and are listed on the NIJ website as a certified and approved supplier www.justnet.org</li>
                          <li> Our products are tested in both field-level and lab-level environments</li>
                          <li> Lab-testing occurs in various laboratories in India, the US, UK and EU regions</li>
                        </ul>
                        <div className="certified-img">
                          <img src="images/certificates-1.png" />
                          <img src="images/certificates-2.png" />
                          <img src="images/certificates-3.png" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane container fade" id="menu2" role="tabpanel">
                      <div className="Certificates-text">
                        <ul>
                          <li>Indian Armour products are NIJ certified and are listed on the NIJ website as a certified and approved supplier www.justnet.org</li>
                          <li> Our products are tested in both field-level and lab-level environments</li>
                          <li> Lab-testing occurs in various laboratories in India, the US, UK and EU regions</li>
                        </ul>
                        <div className="certified-img">
                          <img src="images/certificates-1.png" />
                          <img src="images/certificates-2.png" />
                          <img src="images/certificates-3.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Certificates */}
          </div>
        </section>
        {/* What are the NIJ Levels for Ballistic Vests? */}
        {/* contact form */}
        {/* <section class="contact-us-sec" style="background-image:url('images/contact-bg.png');">
 <div class="container">
    <div class="row">
       <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="contact-detail" data-aos="fade-right" data-aos-offset="300">
           <div class="">
             <div class="contact-1">
            <ul>
              <li class="contact-1-img"><img src="images/icon-1.png"></li>
              <li>Call Us on<br> <a href="tel:9811268600">+91-9811268600</a> </li>
           </ul>
           </div>

           <div class="contact-1">
               <ul>
                   <li class="contact-1-img"><img src="images/icon-2.png"></li>
                   <li>Email<br> <a href="mailto:info@indianarmour.com">info@indianarmour.com</a> </li>
               </ul>
           </div>
           </div>
          </div>
       </div>
       <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="conact-form-ballistic" data-aos="fade-left" data-aos-offset="300">
              <h2>Contact US</h2>
              <form class="ballistic-form">
                  
                  <div class="mb-3">

<input type="email" class="form-control"  placeholder="Name">
</div>

<div class="mb-3">

<input type="email" class="form-control"  placeholder="Email">
</div>

<div class="mb-3">

<input type="text" class="form-control"  placeholder="Location">
</div>

<div class="mb-3">
<textarea class="form-control" rows="5" placeholder="Message"></textarea>
</div>

<div class="mb-3">

<div class="input-group mt-5">
              <a href="#" class="btn know-more-btn submit">Submit
                <span class="input-group-text"><img src="images/arrow.svg"></span>
              </a>
            </div>


</div>

              </form>
          </div>
       </div>
    </div>
 </div>
</section> */}
        {/* contact form */}
        {/* faq */}
        <section className="faq-sec">
          <div className="container">
            <div className="faq-he"><h2>Frequently asked questions</h2></div>
            <div className="row">
              <div className="faq-accordion">
                <div className="row">
                  <div className="col-lg-6" data-aos="fade-right">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Are there any Level 4 ballistic helmets?
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                          <div className="accordion-body">
                            <p>No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            What is a ballistic helmet used for?
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                              No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                            Is the helmet bulletproof?
                          </button>
                        </h2>
                        <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                              No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{/* /col-lg-6 */}
                  <div className="col-lg-6" data-aos="fade-left">
                    <div className="accordion" id="accordionExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne1-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                            Can a ballistic helmet stop a bullet?
                          </button>
                        </h2>
                        <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                              No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-2">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                            Do Kevlar helmets expire?
                          </button>
                        </h2>
                        <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                              No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                            What is the difference between Level 3 and Level 4 body armour? 
                          </button>
                        </h2>
                        <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                              No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{/* /col-lg-6 */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div></div>
      
      

      
      </div>
  )
}

export default BallisticVests
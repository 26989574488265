import React from 'react'

function AboutUs() {
  return (
    <div>
  
    <section className="inner-banner d-flex align-items-center justify-content-center" style={{backgroundImage: 'url("images/About_Us_Banner.png")'}}>
      <div className="inner-banner-content1">
        <div className="container">
          <h1 className="text-white fw-bolder">About Us</h1>
        </div>
      </div>
    </section>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav  aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item"><a href="https://hcltechbeenew.tangence.com">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Blog</li>
          </ol>
        </nav>
      </div>
    </section>
    {/* about us */}
    <section className="about-us right-black-bg py-5">
      <div className="container">
        <div className="row co-reverse">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="about-text" data-aos="fade-right">
              <h1>About Us</h1>
              <p>Indian Armour offers an excellent range of protective products such as Composite Armour Panels, Bullet Resistant Jackets, Helmets, Bomb Suppression Blankets, Bomb Disposal Suits, Combat Boots, Tactical Kit Bags, and fully tried and tested Armoured Vehicles.</p>
              <p>Indian Armour is an ISO 9001:2015 certified company, Manufacturing Specialized ballistic protection product range, with a team of 200 skilled and well-trained workforce. The manufacturing facility is spread over an area of more than 70,000 sq ft and it is located only an hour’s drive away from New Delhi.
                Indian Armour is the most vertically integrated manufacturing company in the world thus making us a very competitive and prosperous organization. Indian Armour, as an elite manufacturer of ballistic protection products, has remained committed to using cutting-edge technology for developing products for both the army and the police, that are lighter, better, and cost-effective. The personal protection products are designed for maximum safety in diverse operational conditions.</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="about-img" data-aos="fade-left">
              <img src="images/about-1.png" className='img-fluid' />
            </div>
          </div>
        </div>
        <div className="extra-text-p">
          <p>All our products are rigorously and comprehensively tested both in the field and in recognized international laboratories of Europe and the USA. The in-house Product Development Team responds swiftly to new customer requirements and challenges. Indian Armour has continuously led the field in creating better and more cost-effective ways to save lives around the world.</p>
        </div>
      </div>
    </section>
    {/* about us */}
    {/* our vision */}
    <section className="our-vision" data-aos="zoom-in">
      <div className="container">
        <div className="our-vision-box">
          <div className="row co-reverse">
            <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
              <div className="our-vision-text">
                <h2>Our Vision</h2>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="our-vision-img">
                <img src="images/our-vision.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* our vision */}
    {/* our Mission */}
    <section className="our-mission py-5 left-bg ">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="about-img">
              <img src="images/about-3.png" className='img-fluid'/>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="about-text">
              <h1>Our Mission</h1>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* our Mission */}
    <section className="our-product right-black-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>Explore Our Products</h2>
            <p className="description" data-aos="fade-up" data-aos-duration={1500}>Indian Armour is the world’s most vertically integrated body armour manufacturer; from yarn to weaving of ballistic Fabric to finished products. Our product range includes Bullet Resistant Jackets, Bullet resistant Vests, Hard Armour Panels, Bullet Resistant Helmets, Bomb Suppression Blankets, Tactical &amp; Combat Gear, and De-Mining Protection Gear.</p>
          </div>
          <div className="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration={1000}>
            <div className="product-list">
              <div className="product-img">
                <h3 className="product-heading">
                  Helmat
                </h3>
                <img src="images/product1.jpg" className="img-fluid" alt="" />
              </div>
              <div className="product-hover">
                <img src="images/product-arrow.png" className="hover-img" />
                <h3 className="product-hover-heading">Helmat</h3>
                <p>Hard armour plates, usually known as (HAP) work in conjunction with the interior armour pack to stop a bullet from penetration or stand alone in a plate carrier vest.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration={1500}>
            <div className="product-list">
              <div className="product-img">
                <h3 className="product-heading">
                  Ballistic Plates
                </h3>
                <img src="images/product1.jpg" className="img-fluid" alt="" />
              </div>
              <div className="product-hover">
                <img src="images/product-arrow.png" className="hover-img" />
                <h3 className="product-hover-heading">Ballistic Plates</h3>
                <p>Hard armour plates, usually known as (HAP) work in conjunction with the interior armour pack to stop a bullet from penetration or stand alone in a plate carrier vest.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration={2000}>
            <div className="product-list">
              <div className="product-img">
                <h3 className="product-heading">
                  Ballistic Vest
                </h3>
                <img src="images/product3.jpg" className="img-fluid" alt="" />
              </div>
              <div className="product-hover">
                <img src="images/product-arrow.png" className="hover-img" />
                <h3 className="product-hover-heading">Ballistic Vest</h3>
                <p>Hard armour plates, usually known as (HAP) work in conjunction with the interior armour pack to stop a bullet from penetration or stand alone in a plate carrier vest.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="collapse " id="collapseExample">
          <div className="row">
            <div className="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration={1000}>
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">
                    Armoured Vehicle
                  </h3>
                  <img src="images/product1.jpg" className="img-fluid" alt="" />
                </div>
                <div className="product-hover">
                  <img src="images/product-arrow.png" className="hover-img" />
                  <h3 className="product-hover-heading">Armoured Vehicle</h3>
                  <p>Hard armour plates, usually known as (HAP) work in conjunction with the interior armour pack to stop a bullet from penetration or stand alone in a plate carrier vest.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration={1500}>
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">
                    Ballistic Shield
                  </h3>
                  <img src="images/product1.jpg" className="img-fluid" alt="" />
                </div>
                <div className="product-hover">
                  <img src="images/product-arrow.png" className="hover-img" />
                  <h3 className="product-hover-heading">Ballistic Shield</h3>
                  <p>Hard armour plates, usually known as (HAP) work in conjunction with the interior armour pack to stop a bullet from penetration or stand alone in a plate carrier vest.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration={2000}>
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">
                    Ballistic Technical Fabric
                  </h3>
                  <img src="images/product3.jpg" className="img-fluid" alt="" />
                </div>
                <div className="product-hover">
                  <img src="images/product-arrow.png" className="hover-img" />
                  <h3 className="product-hover-heading">Ballistic Technical Fabric</h3>
                  <p>Hard armour plates, usually known as (HAP) work in conjunction with the interior armour pack to stop a bullet from penetration or stand alone in a plate carrier vest.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center py">
          <div className="input-group justify-content-center" data-aos="zoom-in">
            <a href="#" className="btn know-more-btn" data-bs-toggle="collapse" data-bs-target="#collapseExample">Explore More Products
              <span className="input-group-text"><i className="fa-solid fa-plus" /></span>
            </a>
          </div>
        </div>
        <div className="border-blk" data-aos="fade-up" data-aos-offset={0}><span className="border-height" /><span className="border-text">Our Products</span>
        </div>
      </div></section>
    
   
  </div>
  )
}

export default AboutUs
import React from 'react'
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";

function BallisticHelmet() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  return (
    <div>
    <section className="inner-banner2 " style={{backgroundImage: 'url("images/ballistic-helmet-bg.png")'}}>
      <div className="inner-banner2-content d-flex align-items-center">
        <div className="container">
          <h1 className=" fw-bolder">Ballistic Helmet</h1>
        </div>
      </div>
    </section>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav>
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item"><a href="index.php">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Ballistic Protection</li>
          </ol>
        </nav>
      </div>
    </section>
    {/* banner */}
    <section className="ballistic-vesta-banner" style={{backgroundImage: 'url("images/bannerg.png")'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p0">
            <div className="ballistic-vesta-left">
              <div className="ballistic-vesta-text">
                {/*   <h1>Ballistic Vests</h1> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="ballistic-vesta-right" data-aos="fade-left">
              <div className="ballistic-vesta-box">
                <ul>
                  <li className="vesta-box"><img src="images/banner-icon-1.svg" /></li>
                  <li>Bespoke<br />Solutions</li>
                </ul>
              </div>
              <div className="ballistic-vesta-box">
                <ul>
                  <li className="vesta-box"><img src="images/banner-icon-2.svg" /></li>
                  <li>Bulk <br />Ordering</li>
                </ul>
              </div>
              <div className="ballistic-vesta-box">
                <ul>
                  <li className="vesta-box"><img src="images/banner-icon-3.svg" /></li>
                  <li>5 Year<br /> Warranty</li>
                </ul>
              </div>
              <div className="ballistic-vesta-box">
                <ul>
                  <li className="vesta-box"><img src="images/banner-icon-4.svg" /></li>
                  <li>NIJ<br /> Certified</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* banner */}
    {/* Mich Ach Helmet with Accessories */}
    <section className="helmets-acces">
      <div className="container">
        <div className="helmets-acces-slider">
          <div className="slider-fors" data-aos="fade-up" data-aos-duration={1500}>
          <Slider asNavFor={nav2} ref={slider1}  arrows={false} >
            <div>
              <div className="helmets-acces-bg" style={{backgroundImage: 'url("images/Helmet-bg.png")'}}>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div className="helmets-img">
                      <img src="images/helmet-img.png" />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div className="helmets-acce-text">
                      <h2>Mich Ach Helmet with Accessories</h2>
                      <p>The MICH/ACH Helmet (Modular Integrated Communication Helmet), is designed as a lighter &amp; more compact version of the PASGT Helmet, with reduced ear coverage, no lip &amp; reduced coverage at the back. This allows for better vision &amp; hearing &amp; also allows the wearer to shoot from the prone position.</p>
                      <a href="#" className="know-more ">Read more <img src="images/arrow.svg" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="helmets-acces-bg" style={{backgroundImage: 'url("images/Helmet-bg.png")'}}>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div className="helmets-img">
                      <img src="images/helmet-img.png" />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div className="helmets-acce-text">
                      <h2>Mich Ach Helmet with Accessories</h2>
                      <p>The MICH/ACH Helmet (Modular Integrated Communication Helmet), is designed as a lighter &amp; more compact version of the PASGT Helmet, with reduced ear coverage, no lip &amp; reduced coverage at the back. This allows for better vision &amp; hearing &amp; also allows the wearer to shoot from the prone position.</p>
                      <a href="#" className="know-more ">Read more <img src="images/arrow.svg" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="helmets-acces-bg" style={{backgroundImage: 'url("images/Helmet-bg.png")'}}>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div className="helmets-img">
                      <img src="images/helmet-img.png" />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div className="helmets-acce-text">
                      <h2>Mich Ach Helmet with Accessories</h2>
                      <p>The MICH/ACH Helmet (Modular Integrated Communication Helmet), is designed as a lighter &amp; more compact version of the PASGT Helmet, with reduced ear coverage, no lip &amp; reduced coverage at the back. This allows for better vision &amp; hearing &amp; also allows the wearer to shoot from the prone position.</p>
                      <a href="#" className="know-more ">Read more <img src="images/arrow.svg" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="helmets-acces-bg" style={{backgroundImage: 'url("images/Helmet-bg.png")'}}>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div className="helmets-img">
                      <img src="images/helmet-img.png" />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div className="helmets-acce-text">
                      <h2>Mich Ach Helmet with Accessories</h2>
                      <p>The MICH/ACH Helmet (Modular Integrated Communication Helmet), is designed as a lighter &amp; more compact version of the PASGT Helmet, with reduced ear coverage, no lip &amp; reduced coverage at the back. This allows for better vision &amp; hearing &amp; also allows the wearer to shoot from the prone position.</p>
                      <a href="#" className="know-more ">Read more <img src="images/arrow.svg" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Slider>
          </div>
         
          <div className="slider-navs" data-aos="fade-up" data-aos-duration={1000}>
          <Slider  asNavFor={nav1}
        ref={slider2}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
       
        >
            <div>
              <div className="helmet-sli">
                <div className="fs-detail">
                  <img src="images/sliderhe-1.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div>
              <div className="helmet-sli">
                <div className="fs-detail">
                  <img src="images/sliderhe-2.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div>
              <div className="helmet-sli">
                <div className="fs-detail">
                  <img src="images/sliderhe-3.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div>
              <div className="helmet-sli">
                <div className="fs-detail">
                  <img src="images/sliderhe-4.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div>
              <div className="helmet-sli">
                <div className="fs-detail">
                  <img src="images/sliderhe-1.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div>
              <div className="helmet-sli">
                <div className="fs-detail">
                  <img src="images/sliderhe-2.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            </Slider>
          </div>
         
        </div>
      </div>
    </section>
    {/* Mich Ach Helmet with Accessories */}
    {/* Ballistic Helmets by Indian Armour */}
    <section className="helmets-in-armour" style={{backgroundImage: 'url("images/BG-.jpg")'}}>
      <div className="container">
        <div className="helmets-in-he">
          <h2>Ballistic Helmets by Indian Armour</h2>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="helmet-in-text" data-aos="fade-right">
              <p>Our ballistic helmets have been specifically designed for use by civil police, military, SWAT, and Special Forces officers. These are also essential for use by law enforcement agencies, para-military forces, federal agencies, first responders as well as media personnel. With an impressive clientele over Europe, Africa, Asia, and the USA, we guarantee you the best performing ballistic helmets at highly competitive prices. These ballistic helmets are lightweight and ergonomically designed to be comfortable for long-time wear preventing operational fatigue. High tenacity ballistic grade fibers such as DuPont™ Kevlar®, Teijin Aramid™ Twaron®, and even UHMWPE are used in multiple layers to ensure superior head protection. These ballistic headgears have also been engineered to protect against fragments and shrapnel that can prove to be fatal to the users. A high level of shock and impact absorption makes our helmets one of the highest performing ranges of ballistic helmets in the market.</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="helmet-in-img" data-aos="fade-left">
              <img src="images/Helmet.png" />
            </div>
          </div>   
        </div>
      </div>
    </section>
    {/* Ballistic Helmets by Indian Armour */}
    {/* tabint */}
    {/* tabint */}
    <section className="new-tabing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="new-tabing-box1" data-aos="zoom-in">
              <p>With over 2 decades of experience in manufacturing advanced combat &amp; tactical helmets, we have developed our products to provide complete satisfaction to our customers. Our head protection gears are of top-notch quality and the helmet profile is minimal, perfect for tactical and stealth situations.</p><p>
              </p><p> Our ballistic headgears are made with advanced composite materials and are built to be durable and reliable, offering extreme comfort in combat zones. These premium bullet-resistant helmets are manufactured to the National Institute of Justice (NIJ) Level IIIA standards and are available in MICH, PASGT, and Special Force designs. We can also offer versions that are in full compliance with the MIL-STD set by the Department of Defense (DOD).</p>
            </div>
          </div>
        </div>
        <div className="new-tabing-box2">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div className="new-tabing-box2-img ballistic-armour-box-img" data-aos="fade-right">
                <img src="images/Helmet-2.png" />
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
              <div className="new-tabing-box2-text" data-aos="fade-left">
                <div className="accordion" id="accordionExample1">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne1-1">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                        What is a tactical helmet, and what are the latest advancements in relation to its materials?
                      </button>
                    </h2>
                    <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo1-2">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                        What situations might necessitate the use of a Ballistic Helmet?
                      </button>
                    </h2>
                    <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo1-3">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                        Are Military Helmets Grenade-Proof? What level of protection can be a Ballistic Helmet?
                      </button>
                    </h2>
                    <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo1-4">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-4" aria-expanded="false" aria-controls="collapseTwo1-4">
                        Are Military Helmets Grenade-Proof? What level of protection can be a Ballistic Helmet?
                      </button>
                    </h2>
                    <div id="collapseTwo1-4" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Certificates */}
        <div className="Certificates">
          <div className="row g-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>
            <div className="col-lg-4 col-sm-4">
              <ul className="nav nav-pills flex-column" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className="nav-link active" data-bs-toggle="pill" href="#home" aria-selected="false" role="tab" tabIndex={-1}>
                    <div className="sps-icon">
                      <img className="tapicon1" src="images/tabicon-1.png" /><img className="tapicon2" src="images/tabicon-1w.png" /> <span>Our Certificates</span>
                    </div>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a className="nav-link " data-bs-toggle="pill" href="#menu1" aria-selected="true" role="tab">
                    <div className="sps-icon">
                      <img className="tapicon1" src="images/tabicon-2.png" /><img className="tapicon2" src="images/tabicon-2w.png" /><span>Our Strengths</span>
                    </div>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a className="nav-link" data-bs-toggle="pill" href="#menu2" aria-selected="false" tabIndex={-1} role="tab">
                    <div className="sps-icon">
                      <img className="tapicon1" src="images/tabicon-3.png" /><img className="tapicon2" src="images/tabicon-3w.png" /> <span>Our Machinery</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-8  col-sm-8 border">
              <div className="tab-content">
                <div className="tab-pane container fade active show" id="home" role="tabpanel">
                  <div className="Certificates-text">
                    <ul>
                      <li>Indian Armour products are NIJ certified and are listed on the NIJ website as a certified and approved supplier www.justnet.org</li>
                      <li> Our products are tested in both field-level and lab-level environments</li>
                      <li> Lab-testing occurs in various laboratories in India, the US, UK and EU regions</li>
                    </ul>
                    <div className="certified-img">
                      <img src="images/certificates-1.png" />
                      <img src="images/certificates-2.png" />
                      <img src="images/certificates-3.png" />
                    </div>
                  </div>
                </div>
                <div className="tab-pane container fade" id="menu1" role="tabpanel">
                  <div className="Certificates-text">
                    <ul>
                      <li>Indian Armour products are NIJ certified and are listed on the NIJ website as a certified and approved supplier www.justnet.org</li>
                      <li> Our products are tested in both field-level and lab-level environments</li>
                      <li> Lab-testing occurs in various laboratories in India, the US, UK and EU regions</li>
                    </ul>
                    <div className="certified-img">
                      <img src="images/certificates-1.png" />
                      <img src="images/certificates-2.png" />
                      <img src="images/certificates-3.png" />
                    </div>
                  </div>
                </div>
                <div className="tab-pane container fade" id="menu2" role="tabpanel">
                  <div className="Certificates-text">
                    <ul>
                      <li>Indian Armour products are NIJ certified and are listed on the NIJ website as a certified and approved supplier www.justnet.org</li>
                      <li> Our products are tested in both field-level and lab-level environments</li>
                      <li> Lab-testing occurs in various laboratories in India, the US, UK and EU regions</li>
                    </ul>
                    <div className="certified-img">
                      <img src="images/certificates-1.png" />
                      <img src="images/certificates-2.png" />
                      <img src="images/certificates-3.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Certificates */}
      </div>
    </section>
    {/* tabint */}
    {/* faq */}
    <section className="faq-sec">
      <div className="container">
        <div className="faq-he">
          <h2>Frequently asked questions</h2>
        </div>
        <div className="row">
          <div className="faq-accordion">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300}>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Are there any Level 4 ballistic helmets?
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                      <div className="accordion-body">
                        <p>No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        What is a ballistic helmet used for?
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading3">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                        Is the helmet bulletproof?
                      </button>
                    </h2>
                    <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /col-lg-6 */}
              <div className="col-lg-6" data-aos="fade-left" data-aos-offset={300}>
                <div className="accordion" id="accordionExample1">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne1-1">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                        Can a ballistic helmet stop a bullet?
                      </button>
                    </h2>
                    <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo1-2">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                        Do Kevlar helmets expire?
                      </button>
                    </h2>
                    <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo1-3">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                        What is the difference between Level 3 and Level 4 body armour? 
                      </button>
                    </h2>
                    <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                      <div className="accordion-body">
                        <p>
                          No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /col-lg-6 */}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default BallisticHelmet
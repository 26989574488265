import React from 'react'
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";

function Home() {
  const bannerSlider = {
    dots: true,
    arrows:false,
     autoplay: true,
      autoplaySpeed: 2000,
  };

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);


  return (
    <>
   
  <section className="banner border-bottom position-relative">
    <div className="container">
      <div className="banner-slider" >
      <Slider {...bannerSlider} >
        {/* div */}
        <div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6">
              <div className="banner-slider-left-content">
                <div className="slider-heading">
                  Modular Body <br /> Armour Manufacturer
                </div>
                <p>
                  From yarn to weaving of ballistic Fabric to finished products.
                  Our product range includes Bullet Resistant Jackets, Bullet
                  resistant Vests, Hard Armour Panels, Bullet Resistant Helmets,
                  Bomb Suppression Blankets, Tactical &amp; Combat Gear, and
                  De-Mining Protection Gear.
                </p>
                <div className="input-group">
                  <a href="#" className="btn know-more-btn">
                    Know more
                    <span className="input-group-text">
                      <img src="images/arrow.svg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="banner-img">
                <img src="images/banner1.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        {/* /div */}
        {/* div */}
        <div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6">
              <div className="banner-slider-left-content">
                <div className="slider-heading">
                  Modular Body <br /> Armour Manufacturer
                </div>
                <p>
                  From yarn to weaving of ballistic Fabric to finished products.
                  Our product range includes Bullet Resistant Jackets, Bullet
                  resistant Vests, Hard Armour Panels, Bullet Resistant Helmets,
                  Bomb Suppression Blankets, Tactical &amp; Combat Gear, and
                  De-Mining Protection Gear.
                </p>
                <div className="input-group">
                  <a href="#" className="btn know-more-btn">
                    Know more
                    <span className="input-group-text">
                      <img src="images/arrow.svg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="banner-img">
                <img src="images/banner1.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        {/* /div */}
        {/* div */}
        <div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6">
              <div className="banner-slider-left-content">
                <div className="slider-heading">
                  Modular Body <br /> Armour Manufacturer
                </div>
                <p>
                  From yarn to weaving of ballistic Fabric to finished products.
                  Our product range includes Bullet Resistant Jackets, Bullet
                  resistant Vests, Hard Armour Panels, Bullet Resistant Helmets,
                  Bomb Suppression Blankets, Tactical &amp; Combat Gear, and
                  De-Mining Protection Gear.
                </p>
                <div className="input-group">
                  <a href="#" className="btn know-more-btn">
                    Know more
                    <span className="input-group-text">
                      <img src="images/arrow.svg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="banner-img">
                <img src="images/banner1.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        {/* /div */}
        {/* div */}
        <div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6">
              <div className="banner-slider-left-content">
                <div className="slider-heading">
                  Modular Body <br /> Armour Manufacturer
                </div>
                <p>
                  From yarn to weaving of ballistic Fabric to finished products.
                  Our product range includes Bullet Resistant Jackets, Bullet
                  resistant Vests, Hard Armour Panels, Bullet Resistant Helmets,
                  Bomb Suppression Blankets, Tactical &amp; Combat Gear, and
                  De-Mining Protection Gear.
                </p>
                <div className="input-group">
                  <a href="#" className="btn know-more-btn">
                    Know more
                    <span className="input-group-text">
                      <img src="images/arrow.svg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="banner-img">
                <img src="images/banner1.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        {/* /div */}
        </Slider>
      </div>
      <div className="border-blk">
        <span className="border-height" />
        <span className="border-text">Welcome</span>
        <div></div>
      </div>
    </div>
  </section>
  <section className="about-us-blk pt-50 left-bg border-bottom bottom-right">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-sm-6" />
        <div className="col-lg-6 col-sm-6">
          <div className="box" data-aos="slide-up">
            <img src="images/box.jpg" />
          </div>
        </div>
        <div
          className="col-lg-6 col-sm-6"
          data-aos="fade-right"
          data-aos-offset={300}
        >
          <h2 className="heading">
            About Indian <br />
            Armour Systems
          </h2>
          <p className="description mb-50">
            Indian Armour is an ISO 9001:2015 certified company, Manufacturing
            Specialized ballistic protection product range, with a team of 200
            skilled and well-trained workforce. The manufacturing facility is
            spread over an area of more than 70,000 sq ft and it is located only
            an hour’s drive away from New Delhi.
          </p>
          <div className="input-group">
            <a href="#" className="btn know-more-btn">
              Know more
              <span className="input-group-text">
                <img src="images/arrow.svg" />
              </span>
            </a>
          </div>
        </div>
        <div
          className="col-lg-6 col-sm-6"
          data-aos="fade-left"
          data-aos-offset={300}
        >
          <div className="about-us-img">
            <img src="images/about-us.jpg" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <div className="border-blk" data-aos="fade-up">
        <span className="border-height" />
        <span className="border-text">About Us</span>
        <div></div>
      </div>
    </div>
  </section>
  <section className="our-product left-bg border-bottom pt-100">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>
            Our Products
          </h2>
          <p
            className="description"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            Indian Armour is the world’s most vertically integrated body armour
            manufacturer; from yarn to weaving of ballistic Fabric to finished
            products. Our product range includes Bullet Resistant Jackets,
            Bullet resistant Vests, Hard Armour Panels, Bullet Resistant
            Helmets, Bomb Suppression Blankets, Tactical &amp; Combat Gear, and
            De-Mining Protection Gear.
          </p>
        </div>
        <div
          className="col-lg-4 col-sm-4"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          <div className="product-list">
            <div className="product-img">
              <h3 className="product-heading">Helmat</h3>
              <img src="images/product1.jpg" className="img-fluid" alt="" />
            </div>
            <div className="product-hover">
              <img src="images/product-arrow.png" className="hover-img" />
              <h3 className="product-hover-heading">Helmat</h3>
              <p>
                Hard armour plates, usually known as (HAP) work in conjunction
                with the interior armour pack to stop a bullet from penetration
                or stand alone in a plate carrier vest.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-sm-4"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <div className="product-list">
            <div className="product-img">
              <h3 className="product-heading">Ballistic Plates</h3>
              <img src="images/product1.jpg" className="img-fluid" alt="" />
            </div>
            <div className="product-hover">
              <img src="images/product-arrow.png" className="hover-img" />
              <h3 className="product-hover-heading">Ballistic Plates</h3>
              <p>
                Hard armour plates, usually known as (HAP) work in conjunction
                with the interior armour pack to stop a bullet from penetration
                or stand alone in a plate carrier vest.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-sm-4"
          data-aos="fade-up"
          data-aos-duration={2000}
        >
          <div className="product-list">
            <div className="product-img">
              <h3 className="product-heading">Ballistic Vest</h3>
              <img src="images/product3.jpg" className="img-fluid" alt="" />
            </div>
            <div className="product-hover">
              <img src="images/product-arrow.png" className="hover-img" />
              <h3 className="product-hover-heading">Ballistic Vest</h3>
              <p>
                Hard armour plates, usually known as (HAP) work in conjunction
                with the interior armour pack to stop a bullet from penetration
                or stand alone in a plate carrier vest.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="collapse " id="collapseExample">
        <div className="row">
          <div
            className="col-lg-4 col-sm-4"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-list">
              <div className="product-img">
                <h3 className="product-heading">Armoured Vehicle</h3>
                <img src="images/product1.jpg" className="img-fluid" alt="" />
              </div>
              <div className="product-hover">
                <img src="images/product-arrow.png" className="hover-img" />
                <h3 className="product-hover-heading">Armoured Vehicle</h3>
                <p>
                  Hard armour plates, usually known as (HAP) work in conjunction
                  with the interior armour pack to stop a bullet from
                  penetration or stand alone in a plate carrier vest.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-4"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <div className="product-list">
              <div className="product-img">
                <h3 className="product-heading">Ballistic Shield</h3>
                <img src="images/product1.jpg" className="img-fluid" alt="" />
              </div>
              <div className="product-hover">
                <img src="images/product-arrow.png" className="hover-img" />
                <h3 className="product-hover-heading">Ballistic Shield</h3>
                <p>
                  Hard armour plates, usually known as (HAP) work in conjunction
                  with the interior armour pack to stop a bullet from
                  penetration or stand alone in a plate carrier vest.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-4"
            data-aos="fade-up"
            data-aos-duration={2000}
          >
            <div className="product-list">
              <div className="product-img">
                <h3 className="product-heading">Ballistic Technical Fabric</h3>
                <img src="images/product3.jpg" className="img-fluid" alt="" />
              </div>
              <div className="product-hover">
                <img src="images/product-arrow.png" className="hover-img" />
                <h3 className="product-hover-heading">
                  Ballistic Technical Fabric
                </h3>
                <p>
                  Hard armour plates, usually known as (HAP) work in conjunction
                  with the interior armour pack to stop a bullet from
                  penetration or stand alone in a plate carrier vest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py">
        <div className="input-group justify-content-center" data-aos="zoom-in">
          <a
            href="#"
            className="btn know-more-btn"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
          >
            Explore More Products
            <span className="input-group-text">
              <i className="fa-solid fa-plus" />
            </span>
          </a>
        </div>
      </div>
      <div className="border-blk" data-aos="fade-up" data-aos-offset={0}>
        <span className="border-height" />
        <span className="border-text">Our Products</span>
      </div>
    </div>
  </section>
  <section className="sps-blk left-bg border-bottom pt-100 pb-50 top-right">
    <div className="container">
      <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>
        Smart Platform Solution
      </h2>
      <div className="row g-0" data-aos="fade-up" data-aos-duration={1500}>
        <div className="col-lg-4 col-sm-4">
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="pill" href="#home">
                <div className="sps-icon">
                  <img src="images/sps-icon1.svg" />{" "}
                  <span>
                    Airborne <br /> Systems
                  </span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="pill" href="#menu1">
                <div className="sps-icon">
                  <img src="images/sps-icon2.svg" />{" "}
                  <span>
                    {" "}
                    Naval <br /> Systems
                  </span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="pill" href="#menu2">
                <div className="sps-icon">
                  <img src="images/sps-icon3.svg" />{" "}
                  <span>
                    {" "}
                    Land <br /> Systems
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-8  col-sm-8 border">
          <div className="tab-content">
            <div className="tab-pane container active" id="home">
              <div className="sps-content">
                <div className="row align-items-center p-4">
                  <div className="col-lg-6">
                    <h3>Airborne Systems</h3>
                    <p>
                      Indian Armour is a high-quality specialized manufacturer
                      of armoured vehicles. Our Armoured Vehicles are
                      manufactured to the highest standard and ballistic
                      integrity. These vehicles are produced under strict
                      manufacturing conditions using the highest quality of
                      ballistic materials.
                    </p>
                    <a href="#" className="know-more ">
                      Know More <img src="images/arrow.svg" />
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="sps-img">
                      <img src="images/tank.jpg" className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane container fade" id="menu1">
              <div className="sps-content">
                <div className="row align-items-center p-4">
                  <div className="col-lg-6">
                    <h3>Naval Systems</h3>
                    <p>
                      Indian Armour is a high-quality specialized manufacturer
                      of armoured vehicles. Our Armoured Vehicles are
                      manufactured to the highest standard and ballistic
                      integrity. These vehicles are produced under strict
                      manufacturing conditions using the highest quality of
                      ballistic materials.
                    </p>
                    <a href="#" className="know-more ">
                      Know More <img src="images/arrow.svg" />
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="sps-img">
                      <img src="images/tank.jpg" className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane container fade" id="menu2">
              <div className="sps-content">
                <div className="row align-items-center p-4">
                  <div className="col-lg-6">
                    <h3>Land Systems</h3>
                    <p>
                      Indian Armour is a high-quality specialized manufacturer
                      of armoured vehicles. Our Armoured Vehicles are
                      manufactured to the highest standard and ballistic
                      integrity. These vehicles are produced under strict
                      manufacturing conditions using the highest quality of
                      ballistic materials.
                    </p>
                    <a href="#" className="know-more ">
                      Know More <img src="images/arrow.svg" />
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="sps-img">
                      <img src="images/tank.jpg" className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-blk" data-aos="fade-up" data-aos-duration={500}>
        <span className="border-height" />
        <span className="border-text">Smart Platform Solution</span>
        <div></div>
      </div>
    </div>
  </section>
  <section className="featured-solution border-bottom pt-50 pb-100 left-bottom right-black-bg">
    <div className="container">
      <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>
        Featured Solutions
      </h2>
      <div className="row">
        <div className="col-lg-12">
          <div className="featured-solution-slider">
         
            <div
              className="slider-nav"
              data-aos="fade-up"
              data-aos-duration={1000}
            >
               <Slider  asNavFor={nav1}
        ref={slider2}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        >
              <div>
                <div className="fs-detail">
                  <img
                    src="images/feature-solution1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="fs-detail">
                  <img
                    src="images/feature-solution2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="fs-detail">
                  <img
                    src="images/feature-solution3.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="fs-detail">
                  <img
                    src="images/feature-solution1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="fs-detail">
                  <img
                    src="images/feature-solution2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="fs-detail">
                  <img
                    src="images/feature-solution3.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              </Slider>
            </div>
           
            
            <div
              className="slider-for"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <Slider asNavFor={nav2} ref={slider1} arrows={false}>
              <div>
                <div className="fs-list">
                  <div className="row g-0">
                    <div className="col-lg-6 col-sm-6">
                      <div className="fs-list-content">
                        <h3>Army Helmet</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida.{" "}
                        </p>
                        <a href="#" className="know-more ">
                          Know More <img src="images/arrow.svg" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="fs-list-img">
                        <img
                          src="images/feature-solution1.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="fs-list">
                  <div className="row g-0">
                    <div className="col-lg-6 col-sm-6">
                      <div className="fs-list-content">
                        <h3>Army Helmet</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida.{" "}
                        </p>
                        <a href="#" className="know-more ">
                          Know More <img src="images/arrow.svg" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="fs-list-img">
                        <img
                          src="images/feature-solution1.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="fs-list">
                  <div className="row g-0">
                    <div className="col-lg-6 col-sm-6">
                      <div className="fs-list-content">
                        <h3>Army Helmet</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida.{" "}
                        </p>
                        <a href="#" className="know-more ">
                          Know More <img src="images/arrow.svg" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="fs-list-img">
                        <img
                          src="images/feature-solution1.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="fs-list">
                  <div className="row g-0">
                    <div className="col-lg-6 col-sm-6">
                      <div className="fs-list-content">
                        <h3>Army Helmet</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida.{" "}
                        </p>
                        <a href="#" className="know-more ">
                          Know More <img src="images/arrow.svg" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="fs-list-img">
                        <img
                          src="images/feature-solution1.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="border-blk" data-aos="fade-up">
        <span className="border-height" />
        <span className="border-text">Featured Solution</span>
        <div></div>
      </div>
    </div>
  </section>
  <section className="our-process position-relative pt-5">
    <div className="container">
      <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>
        Our Process
      </h2>
    </div>
    <div className="container-fluid p-0 position-relative">
      <img src="images/video-bg.jpg" className="img-fluid" alt="" />
      <a
        href="https://www.youtube.com/watch?v=79pc_hdmCIU"
        className="glightbox video-pop-up"
        data-fancybox=""
        data-aos="zoom-in"
      >
        <i className="fa-sharp fa-solid fa-play" />
      </a>
      <div className="video-content" data-aos="fade-left">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam
      </div>
    </div>
    <div className="container">
      <div className="border-blk" data-aos="fade-up" data-aos-duration={500}>
        <span className="border-height" />
        <span className="border-text">Video</span>
        <div></div>
      </div>
    </div>
  </section>
</>

  )
}

export default Home
import React from 'react'

function BlogDetail() {
  return (
    <div>
    <section className="inner-banner d-flex align-items-center justify-content-center" style={{backgroundImage: 'url("images/blog-banner.jpg")'}}>
      <div className="inner-banner-content1">
        <div className="container">
          <h1 className="text-white fw-bolder">Blogs</h1>
        </div>
      </div>
    </section>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav >
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Combat Goggles and Integrated Visual...</li>
          </ol>
        </nav>
      </div>
    </section>
    <section className="blog-detail-blk pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p><a href="#" className="blog-detail-date">September 15, 2022</a></p>
            <h2>Combat Goggles and Integrated Visual Augmentation System</h2>
            <div className="blog-detail-img">
              <img src="images/blog-detail.png" className="img-fluid w-100" />
            </div>
            <p>One of the military’s most recent investments is in a futuristic combat goggle that makes it possible for soldiers to see the battlefield in a brand-new way. These futuristic goggles that the soldiers are wearing sound like something out of a science fiction film, since they have the potential to transform battlegrounds. A heads-up display allows the soldiers to monitor terrain maps and an overhead compass in real time helping to clear a farm of war. And don’t worry if it’s dark and hazy; the goggles can see right through it. Not only can soldiers see in the dark but they can see now through smoke and even peak around corners.</p>
            <p>These goggles are designed to display location data, 3D and thermal images, and allow warriors to see through smoke. The wearer may check their position with a compass and find members of the squad using an almost miniaturised blue force tracker. These combat goggles are designed to upgrade situation awareness and the way the soldiers fight in the battle fields. All of these capabilities, that will profoundly alter how troops fight, are possible thanks to a complicated yet user-friendly technology known as Integrated Visual Augmentation Systems. </p>
            <p>The Integrated Visual Augmentation System (IVAS) is a head gear based on augmented-reality that overlays digital elements like maps or video displayed on top of the real world in front of the army personnel. </p>
            <p>The new technology has been developed to provide soldiers with the information they’ll need to figure out what’s next, whether this is the right course of action, and also to display network information in a way that doesn’t overwhelm the soldier but instead tells him where he needs to go, where his buddies are, and where the enemy is. The whole system is developed in a soldier centred design. </p>
            <h3>Combat Goggles and Integrated Visual Augmentation System</h3>
            <p>Soldiers can plan missions and see their entire position projected on a holographic map using these goggles. It also means better communication. A soldier can mark enemy position and share that across the board. </p>
            <p>These spectacles are particularly useful in training sessions, as they allow soldiers to view realistic holograms of enemy fighters in order to clear out rooms. Soldiers will be able to train against virtual enemies overlaid on their real-world field of vision using the new technology, which includes digital wide-angle multi-spectral imaging with light-amplifying night vision, thermal, and day colour optics, object outlining emphasis, 3D map and waypoint overlays, a picture-in-picture display of weapons sights, and a Squad Immersive Virtual Trainer. Soldiers can also watch a video game-like replay of their training to spot any errors. The cross-hair is visible via the visor of the IVAS system, and the Army may utilise this to create ‘after-action’ reports summarising a soldier’s accuracy and efficiency during a training session.</p> 
            <p>The goggle is a significant upgrade over the current battlefield gear. The Night vision no longer means looking through what looks like a straw, but rather a complete 180-degree view. Thermal technology achieves something a night vision goggle can’t: it makes smoky situations as clear as day with the turn of a switch. The addition of a scope to rifles allows troops to work with perilous urban war-fare. IVAS provides the tools that soldiers require to reclaim and retain an advantage in multi-domain operations on increasingly complex and unpredictable battlefields. As a result, these goggles are seen as a game changer for troops. Developments are going on to shrink the current iteration of IVAS so that its works well with the existing ballistic helmets.</p>
          </div>
          <a href="blog.php" className="back"><i className="fa-solid fa-arrow-left-long" /> Back </a>
        </div>
      </div>
    </section>
  </div>
  )
}

export default BlogDetail
import React from 'react'
import { Link } from 'react-router-dom'
function Footer() {
  return (
   <>
    <section className="upper-footer" data-aos="fade-up">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <div className="up-footer-1">
            <img src="images/logo-1.png" alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div className="up-footer-1">
            <div className="img-ro">
              <img src="images/phone2.svg" />
            </div>
            <div className="ro-text">
              <p>Call Us on</p>
              <a href="tel:+91-9811268600">+91-9811268600</a>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
          <div className="up-footer-1">
            <div className="img-ro">
              <img src="images/mail.svg" />
            </div>
            <div className="ro-text">
              <p>Email</p>
              <a href="mailto:info@indianarmour.com">info@indianarmour.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer data-aos="fade-up" data-aos-duration={1000}>
    {/* <div class="container">
    <a href="#"><img src="images/footer-logo.svg" class="footer-logo" alt=""></a>
  </div> */}
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-4">
          <div className="footer-col">
            <h4>Address</h4>
            <p className="d-flex align-items-center">
              <span className="address-icon">
                <img src="images/map.svg" />
              </span>{" "}
              <span className="address-content">
                Universal Lane, Village Dudhola, P O Bhagola Palwal,
                Haryana-121102, India
              </span>
            </p>
            <p className="d-flex align-items-center">
              <span className="address-icon">
                <img src="images/phone.svg" />
              </span>{" "}
              <span className="address-content">
                <a href="tel:+91-9811268600">+91-9811268600,</a>
                <br />
                <a href="tel:+91-8750050215">+91-8750050215</a>
              </span>
            </p>
            <p className="d-flex align-items-center">
              <span className="address-icon">
                <img src="images/envlop.svg" />
              </span>{" "}
              <span className="address-content">
                <a href="mailto:info@indianarmour.com">info@indianarmour.com</a>
              </span>
            </p>
          </div>
        </div>
        <div className="col-lg-2 col-sm-6">
          <div className="footer-col">
            <h4>Categories</h4>
            <ul className="footer-link">
              <li>
                <a href="#">Ballistic </a>
              </li>
              <li>
                <a href="#">Protection </a>
              </li>
              <li>
                <a href="#">Ballistic Helmet </a>
              </li>
              <li>
                <a href="#">Ballistic Plates </a>
              </li>
              <li>
                <a href="#">Ballistic Vest</a>
              </li>
              <li>
                <a href="#">Gear</a>
              </li>
              <li>
                <a href="#">Head Gear</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-sm-6">
          <div className="footer-col">
            <h4>Resources</h4>
            <ul className="footer-link">
              <li>
                <a href="#">Brochure</a>
              </li>
              <li>
                 <Link to="about">About Us</Link>
              </li>
              <li>
                <Link to="contact">Contact Us</Link>
              </li>
              <li>
                <Link to="blog">Blog</Link>
              </li>
              <li>
                <Link to="faq">Faq</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="footer-col">
            <h4>Signup for Newsletter</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed</p>
            <div className="newsletter">
              <input
                type="text"
                className="form-control"
                name=""
                placeholder="Enter your email"
              />
              <div className="input-group">
                <a href="#" className="btn know-more-btn submit">
                  Submit
                  <span className="input-group-text">
                    <img src="images/arrow.svg" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="social-media d-flex align-items-center">
            <span>Follow Us:</span>
            <ul className="d-flex list-inline m-0">
              <li>
                <a href="#">
                  <i className="fa-brands fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-linkedin-in" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div className="bottom-strip">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center d-block">
            Copyright © 2023 <span>|</span> <a href="#">FAQs</a> <span>|</span>{" "}
            <a href="#">Privacy Policy</a> <span>|</span>{" "}
            <a href="#">Certifications</a> <span>|</span>{" "}
            <a href="#">Bespoke-Solutions</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

  )
}

export default Footer
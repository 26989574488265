import './App.css';
import React from 'react';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './pages/Home';
import $ from "jquery";
import { Routes, Route } from "react-router-dom";
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import AboutUs from './pages/AboutUs';
import Faq from './pages/Faq';
import BlogDetail from './pages/BlogDetail';
import Certification from './pages/Certification';
import Bespoke from './pages/Bespoke';
import BallisticProtection from './pages/BallisticProtection';
import BallisticHelmet from './pages/BallisticHelmet';
import BallisticVests from './pages/BallisticVests';
import { Helmet } from "react-helmet"
function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
     <Helmet>
        <script src="js/home.js"></script>
      </Helmet>
    <Header />
    <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blogDetail" element={<BlogDetail />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/certification" element={<Certification />} />
          <Route exact path="/bespoke" element={<Bespoke />} />
          <Route exact path="/ballisticprotection" element={<BallisticProtection />} />
          <Route exact path="/ballistichelmet" element={<BallisticHelmet />} />
          <Route exact path="/ballisticvests" element={<BallisticVests />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
